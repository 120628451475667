import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { Layout } from "../components/layout/layout";

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "contact.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout background={data.hero} title="Oops! Cette page n'existe pas" />
  );
};

export default NotFound;
